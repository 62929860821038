import { CabButton } from "@CabComponents/CabButton";
import CabCollapseMenu from "@CabComponents/CabCollapseMenu";
import { CabIcon } from "@CabComponents/CabIcon";
import { Box, Divider, SxProps, Typography } from "@mui/material";
import { ReactElement } from "react";
import { useCabinetText } from "../../../CabinetContext";
import colors from "../../../colors";
import { GlobalModalComponentName, RootState, ThunkDispatchType, actions } from "../../../store";

import { useDispatch, useSelector } from "react-redux";
import { PAGE_URL } from "../../../constants";
import { useNavigate } from "react-router";
import { IoAdd, IoLockClosedOutline } from "react-icons/io5";

export interface CreateMeetingButtonProps {
  buttonTitle: string;
  buttonSx?: SxProps;
  buttonId?: string;
  onCreateMeetingOverride?: () => void;
  onCreateReusableMeetingOverride?: () => void;
  onCreatePollOverride?: () => void;
}

const CreateMeetingButton = ({
  buttonTitle, buttonSx, buttonId, onCreateMeetingOverride, onCreateReusableMeetingOverride, onCreatePollOverride
}: CreateMeetingButtonProps): ReactElement => {

  const dispatch = useDispatch<ThunkDispatchType>();
  const user = useSelector((state: RootState) => state.auth.user);
  const navigate = useNavigate();

  const [newMeetingText] = useCabinetText(['new-meeting-button']);

  const handleCreateMeeting = onCreateMeetingOverride || (() => {
    navigate(PAGE_URL.SCHEDULE, { state: { createMeeting: true}});
  });

  const handleCreateReusableMeeting = onCreateReusableMeetingOverride || (() => {
    navigate(PAGE_URL.SCHEDULE, {state: { createReusableMeeting: true}});
  });

  const handleCreatePoll = onCreatePollOverride || (() => {
    navigate(PAGE_URL.SCHEDULE, {state: { createMeetingPoll: true}});
  });

  const handleClickNewPoll = () => {
    if (user?.features.MEETING_POLLS) {
      handleCreatePoll();
    } else {
      dispatch(actions.globalModal.openModal(GlobalModalComponentName.CABINET_PROMO));
    }
  };

  return (
    <CabCollapseMenu
      popOverSx={theme => ({ marginTop: theme.spacing(0.5) })}
      buttonGroupVariant="text"
      buttonGroupColor="inherit"
      buttonGroupId="CreateMeetingButton-create-meeting-popover"
      target={
        <CabButton
          buttonType="primary"
          icon={<CabIcon Icon={IoAdd} alt=''/>}
          sx={buttonSx}
          id={buttonId}
        >
          {buttonTitle}
        </CabButton>
      }
      buttons={<>
        <CabButton 
          buttonType="text"
          onClick={() => {
            handleCreateMeeting();
          }}
          sx={{ maxWidth: 230, textAlign: 'start', color: colors.black900 }}
        >
          <Box display='flex'>
            <Typography variant='h2'>One-off Meeting</Typography>
          </Box>
          <Typography variant="body2" color={colors.black800}>
            {newMeetingText}
          </Typography> 
        </CabButton>
        <Divider sx={{backgroundColor: colors.black200}}/>
        <CabButton 
          buttonType="text"
          onClick={() => {
            handleCreateReusableMeeting();
          }}
          sx={{ maxWidth: 230, textAlign: 'start', color: colors.black900 }}
        >
          <Box display='flex'>
            <Typography variant='h2'>Reusable Meeting</Typography>
          </Box>
          <Typography variant="body2" color={colors.black800}>
            Select times that can be used to book multiple meetings.
          </Typography> 
        </CabButton>
        <Divider sx={{backgroundColor: colors.black200}}/>
        <CabButton
          buttonType="text"
          onClick={handleClickNewPoll}
          sx={{ maxWidth: 230, textAlign: 'start', color: colors.black900 }}
        >
          <Box display='flex'>
            <Typography variant='h2'>Meeting Poll</Typography>
            {!user?.features.MEETING_POLLS && (
              <CabIcon
                Icon={IoLockClosedOutline}
                alt='Locked'
                slot='end'
                sx={{ fontSize: 19 }}
              />
            )}
          </Box>
          <Typography variant="body2" color={colors.black800}>
            Schedule a group meeting after offering times for a vote.
          </Typography>
        </CabButton>
      </>}
      popOverTitle=""
    />
  );
};

export default CreateMeetingButton;